import React from "react";

/* Donations Blurb
============================================================================= */

const Blurb = () => {
	// Config

	// Render
	return (
		<div className="w-full bg-gray-50 pattern-cross-dots section-wide">
			<div />
		</div>
	);
};

/* Export
============================================================================= */

export default Blurb;
