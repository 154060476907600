import React from "react";
import { Link } from "gatsby";
import { SEO } from "components";
import { Blurb, DonationList } from "components/donations";
import { PageHeading } from "components/layout";

/* Donate Page
============================================================================= */

const DonatePage = () => {
	// Config
	return (
		<>
			<SEO title="Donate" />
			<PageHeading title="Donate" />
			<Blurb />
			<DonationList />
		</>
	);

	// Render
};

/* Export
============================================================================= */

export default DonatePage;
